<template>
    <div>
        <footer-navigation />

        <v-sheet color="primary" class="py-18px py-md-24px">
            <v-container>
                <v-row justify="center" align="center" class="ma-n10px ma-md-n14px">
                    <v-col cols="12" md="auto" class="pa-10px pa-md-14px">
                        <div class="txt white--text text-center">find out our new style</div>
                    </v-col>
                    <v-col cols="12" md="auto" class="pa-10px pa-md-14px">
                        <v-btn large outlined color="white" class="footer-guide-button">
                            <span class="font-weight-medium">from New Style Guide</span>
                            <div class="icon-open" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <footer class="footer">
            <div class="footer-head">
                <v-container class="container--lg">
                    <v-row align="center" justify="space-between">
                        <v-col cols="12" sm="auto">
                            <!-- <div class="divider-group divider-group--large">
                                <ul>
                                    <li><router-link to="/">Tab01</router-link></li>
                                    <li><router-link to="/">Tab02</router-link></li>
                                    <li><router-link to="/">Tab03</router-link></li>
                                    <li><router-link to="/">Tab04</router-link></li>
                                    <li><router-link to="/">Tab05</router-link></li>
                                </ul>
                            </div> -->
                        </v-col>
                        <v-col cols="12" sm="auto" class="ml-auto">
                            <footer-family-site />
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <div class="footer-body">
                <v-container class="container--lg">
                    <v-row align="center" class="row--sm">
                        <v-col>
                            <div class="inline-group">
                                <ul>
                                    <li><div class="font-size-14 font-weight-medium mb-12px mb-md-16px grey--text text--darken-4">Villeroy &amp; Boch</div></li>
                                    <template v-for="item in items" >
                                        <li :key="item" :class="{ clear : item.clear}">
                                            <span class="grey--text">
                                                {{ item.name }}
                                            </span>
                                            {{ item.text }}
                                        </li>
                                    </template>
                                    <li class="py-md-0"><span class="footer-account">무통장입금 기업은행 960-004190-04-040</span></li>
                                </ul>
                            </div>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-row class="row--sm">
                                <v-col cols="auto">
                                    <v-btn x-small class="grey darken-3" icon href="/" target="_blank">
                                        <v-img width="14" src="/images/icon/icon-instagram.svg"></v-img>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-small class="grey darken-3" icon href="/" target="_blank">
                                        <v-img width="14" src="/images/icon/icon-facebook.svg"></v-img>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-small class="grey darken-3" icon href="/" target="_blank">
                                        <v-img width="14" src="/images/icon/icon-x.svg"></v-img>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-small class="grey darken-3" icon href="/" target="_blank">
                                        <v-img width="14" src="/images/icon/icon-youtube.svg"></v-img>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-24px mt-md-30px" />
                </v-container>
            </div>

            <div class="footer-foot">
                <v-container class="container--lg">
                    <v-row align="center">
                        <v-col cols="auto">
                            <div class="txt txt--xs">© Villeroy &amp; Boch Group 2024</div>
                        </v-col>
                        <!-- <v-spacer />
                        <v-col cols="12" md="auto">
                            <div class="txt txt--xs">Business Number. <span class="txt--dark">120-86-43097</span></div>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <div class="txt txt--xs">registration number. <span class="txt--dark">2016-HwasungJangan-01</span></div>
                        </v-col> -->
                    </v-row>
                </v-container>
            </div>
        </footer>
    </div>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import IconArrowRounded from "../icons/icon-arrow-rounded.vue";
import FooterFamilySite from "./footer-family-site.vue";
import FooterNavigation from "@/components/client/footer/footer-navigation.vue"

export default {
    components: {
        UDialog,
        IconArrowRounded,
        FooterFamilySite,
        FooterNavigation,
    },
    data: () => {
        return {
            attrs_input,

            carts: [],

            searchKey: "name",
            searchValue: null,

            shows: false,

            // scrollTop
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",

            items: [
                {
                    name: "상호명",
                    text: "조이포라이프 주식회사",
                    clear: true,
                },
                {
                    name: "대표이사",
                    text: "하기호",
                },
                {
                    name: "주소",
                    text: "경기도 성남시 대왕판교로 187 / 경기도 화성시 장안면 장안로 480-192",
                },
                {
                    name: "전자우편주소",
                    text: "jflmall@joeyforlife.com",
                },
                {
                    name: "팩스",
                    text: "070-5038-4807",
                    
                },
                {
                    name: "사업자등록번호",
                    text: "120-86-43097",
                    clear: true,
                },
                {
                    name: "통신판매업신고",
                    text: "제2016-화성장안-01호",
                },
                {
                    name: "개인정보책임관리자",
                    text: "하기호",
                },
                {
                    name: "cs직통번호",
                    text: "070-4010-5061",
                },
            ]
        };
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            this.$nextTick(function () {
                this.footer();
            });
        },

        // footer
        footer: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
                scrollTop();
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                }
                scrollAnimate();
            }

            // Scroll Top
            function scrollTop() {
                if (scrollPosition > window.innerHeight / 2) {
                    HTML.setAttribute("data-scroll-top", "true");
                } else {
                    HTML.removeAttribute("data-scroll-top");
                }
            }
        },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    background-color: var(--v-grey-lighten5);
}
.footer-account {
    font-size: 14px;
    font-weight: bold;
    color: var(--v-primary-base);
}

@media (min-width: 768px) {
    .footer-account {
        font-size: 16px;
    }
}
</style>