var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer footer-navigation"
  }, [_c('div', {
    staticClass: "footer-top-button"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "large": "",
      "elevation": "4",
      "color": "white"
    },
    on: {
      "click": _vm.scrollToTop
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-up.svg"
    }
  })], 1)], 1), _c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [item.value ? [_c('a', {
      staticClass: "txt txt--sm txt--dark font-weight-medium font-secondary pb-18px pb-md-24px",
      attrs: {
        "href": item.value
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])] : [_c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium font-secondary pb-18px pb-md-24px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], _c('v-row', {
      staticClass: "row--sm"
    }, [item.text === 'Products' ? [_vm._l(_vm.categories, function (category) {
      return [_c('v-col', {
        key: category,
        attrs: {
          "cols": "12"
        }
      }, [_c('a', {
        staticClass: "txt txt--sm txt--dark font-weight-light",
        attrs: {
          "href": `/shop?category=${category.code}`
        }
      }, [_vm._v(" " + _vm._s(category.name) + " ")])])];
    })] : [_vm._l(item.children, function (child) {
      return [_c('v-col', {
        key: child,
        attrs: {
          "cols": "12"
        }
      }, [_c('a', {
        staticClass: "txt txt--sm txt--dark font-weight-light",
        attrs: {
          "href": child.value
        }
      }, [_vm._v(" " + _vm._s(child.text) + " ")])])];
    })]], 2)], 2)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }