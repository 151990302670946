var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-visual"
  }, [_c('v-card', {
    staticClass: "main-visual__inner",
    attrs: {
      "img": "/images/main/visual.jpg",
      "tile": "",
      "flat": ""
    }
  }, [_c('v-container', [_c('v-sheet', {
    staticClass: "main-visual__text",
    attrs: {
      "color": "white"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mt-auto mb-12px mb-md-16px"
  }, [_vm._v("New: Antao")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("자연에서 영감을 받은 새로운 전체적인 욕실 컬렉션")]), _c('v-btn', {
    staticClass: "mt-8px pa-0",
    attrs: {
      "text": "",
      "large": "",
      "ripple": false
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("Antao 보러 가기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }