var render = function render(){
  var _vm$value, _vm$value$thumb;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "shop-product-card",
    attrs: {
      "to": `/shop/products/${_vm.value._id}`,
      "height": "100%",
      "flat": "",
      "tile": ""
    }
  }, [_c('like-btn', {
    attrs: {
      "params": {
        _product: (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value._id
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var active = _ref.active,
          toggle = _ref.toggle;
        return [_c('v-btn', {
          staticClass: "like-button favorite white v-size--xx-small",
          attrs: {
            "icon": ""
          },
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, [active ? [_c('div', {
          staticClass: "like-button-icon like-button-icon--active"
        })] : [_c('div', {
          staticClass: "like-button-icon"
        })]], 2)];
      }
    }])
  }), _c('v-card', {
    attrs: {
      "flat": "",
      "tile": "",
      "img": (_vm$value$thumb = _vm.value.thumb) === null || _vm$value$thumb === void 0 ? void 0 : _vm$value$thumb.url
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('v-card-text', [_c('div', {
    staticClass: "txt txt--sm mb-16px font-weight-medium text-truncate-2"
  }, [_vm._v(_vm._s(_vm.value.name) + " " + _vm._s(_vm.value.code || ""))]), !_vm.value.sells || _vm.value.stock == 0 ? [_c('em', {
    staticClass: "txt txt--sm"
  }, [_vm._v("품절")])] : [_vm.discountPrice ? [_c('div', {
    staticClass: "txt font-size-12 text-decoration-line-through"
  }, [_vm._v(_vm._s(_vm.value.price.format()))])] : _vm._e(), _c('v-row', {
    staticClass: "row--xs"
  }, [_vm.discountRate ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm primary--text font-weight-medium"
  }, [_vm._v(_vm._s(_vm.discountRate) + "%")])])] : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v(_vm._s(_vm.salePrice.format()))]), _c('span', {
    staticClass: "txt font-size-12 ml-4px"
  }, [_vm._v("원")])])])], 2)]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }