<template>
    <div class="header-menu">
        <v-row class="row--lg">
            <template v-if="!accessToken">
                <v-col cols="auto" class="d-lg-none">
                    <v-btn to="/login" text plain width="24" min-width="24" class="header-menu-button">
                        <v-img width="24" height="24" src="/images/icon/icon-user.svg" class="header-menu-icon" />
                        <div>로그인</div>
                    </v-btn>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="auto" class="d-lg-none">
                    <v-btn text plain width="24" min-width="24" class="header-menu-button">
                        <v-img width="24" height="24" src="/images/icon/icon-user.svg" class="header-menu-icon" />
                        <div>마이페이지</div>
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="d-lg-none">
                    <v-btn text plain width="24" min-width="24" class="header-menu-button" @click="logout">
                        <v-img width="24" height="24" src="/images/icon/icon-user.svg" class="header-menu-icon" />
                        <div>로그아웃</div>
                    </v-btn>
                </v-col>
            </template>
            <v-col cols="auto" class="d-none d-lg-block">
                <v-menu transition="slide-y-transition" :close-on-content-click="false" offset-y bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text plain width="24" min-width="24" class="header-menu-button">
                            <v-img width="24" src="/images/icon/icon-search.svg" class="header-menu-icon" />
                            <div>검색</div>
                        </v-btn>
                    </template>

                    <v-sheet>
                        <div class="pa-16px">
                            <header-search-bar />
                        </div>
                    </v-sheet>
                </v-menu>
            </v-col>
            <v-col cols="auto">
                <v-btn text plain width="24" min-width="24" class="header-menu-button">
                    <v-badge overlap content="1">
                        <v-img width="24" src="/images/icon/icon-wishlist.svg" class="header-menu-icon" />
                    </v-badge>
                    <div>위시리스트</div>
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn text plain width="24" min-width="24" class="header-menu-button">
                    <v-badge overlap content="1">
                        <v-img width="24" src="/images/icon/icon-cart.svg" class="header-menu-icon" />
                    </v-badge>
                    <div>장바구니</div>
                </v-btn>
            </v-col>
            <v-col cols="auto" class="d-lg-none">
                <v-btn text plain width="24" min-width="24" class="header-menu-button" @click="toggleDrawer">
                    <v-img width="24" src="/images/icon/icon-menu.svg" class="header-menu-icon" />
                    <div>메뉴</div>
                </v-btn>

                <header-drawer :drawer="drawer" @toggle-drawer="toggleDrawer" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import navigation from "@/store/ui/index.js";

import HeaderSearchBar from "@/components/client/header/header-search-bar.vue";
import HeaderDrawer from "@/components/client/header/header-body/header-drawer.vue";

export default {
    components: {
        HeaderSearchBar,
        HeaderDrawer,
    },
    data: () => ({
        drawer: false,
        items: navigation,
    }),
    methods: {
        toggleDrawer() {
            this.drawer = !this.drawer;
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn.header-menu-button {
    padding: 0;
    height: auto !important;
    font-weight: 300;
    font-size: 10px !important;
    font-family: var(--font-secondary);
    line-height: 1;
    &::before {
        display: none;
    }
    .header-menu-icon {
        margin: 0 auto 4px;
    }
    ::v-deep {
        .v-btn__content {
            display: block;
        }
        .v-badge__badge {
            min-width: 16px;
            height: 16px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: var(--font-secondary);
            font-size: 8px;
            &:hover {
                color: #fff;
            }
        }
    }
}
@media (min-width: 1024px) {
}
</style>