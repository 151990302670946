<template>
    <main-section class="main-story">
        <v-container>
            <v-sheet class="section-bg" />

            <v-row align="center" class="row--lg">
                <v-col cols="12" md="8" order-md="2">
                    <div class="story-image-wrap">
                        <v-img src="/images/main/story.jpg" class="d-md-none" />
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div>
                        <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">브랜드 이야기</h2>
                        <p class="txt txt--sm">
                            시대를 초월한 우아함, 혁신적인 디자인, <br class="d-none d-md-block" />
                            뛰어난 품질: 이것이 바로 Villeroy & <br class="d-none d-md-block" />
                            Boch 브랜드가 1748년부터 상징해 온 것입니다.
                        </p>
                        <v-btn large tile href="https://www.villeroyboch-group.com/en/" target="_blank" class="white mt-18px mt-md-24px px-20px px-md-26px rounded-xs">
                            <span class="txt txt--xs txt--dark font-weight-medium">더 읽기</span>
                            <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </main-section>
</template>

<script>
import MainSection from "@/components/client/templates/main-section.vue";

export default {
    components: {
        MainSection,
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 7);
        height: 100%;
    }
    .container {
        padding-top: var(--grid-gutter-lg);
        padding-bottom: var(--grid-gutter-lg);
    }
    .story-image-wrap {
        height: calc(638px - var(--grid-gutter-lg) * 2);
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: calc(-1 * var(--grid-gutter-lg));
            // left: calc(-1 * var(--grid-gutter-lg));
            aspect-ratio: calc(1133 / 638);
            background-image: url(/images/main/story.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            height: calc(100% + var(--grid-gutter-lg) * 2);
        }
    }
}
</style>