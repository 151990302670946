var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-menu"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [!_vm.accessToken ? [_c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-menu-button",
    attrs: {
      "to": "/login",
      "text": "",
      "plain": "",
      "width": "24",
      "min-width": "24"
    }
  }, [_c('v-img', {
    staticClass: "header-menu-icon",
    attrs: {
      "width": "24",
      "height": "24",
      "src": "/images/icon/icon-user.svg"
    }
  }), _c('div', [_vm._v("로그인")])], 1)], 1)] : [_c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-menu-button",
    attrs: {
      "text": "",
      "plain": "",
      "width": "24",
      "min-width": "24"
    }
  }, [_c('v-img', {
    staticClass: "header-menu-icon",
    attrs: {
      "width": "24",
      "height": "24",
      "src": "/images/icon/icon-user.svg"
    }
  }), _c('div', [_vm._v("마이페이지")])], 1)], 1), _c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-menu-button",
    attrs: {
      "text": "",
      "plain": "",
      "width": "24",
      "min-width": "24"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('v-img', {
    staticClass: "header-menu-icon",
    attrs: {
      "width": "24",
      "height": "24",
      "src": "/images/icon/icon-user.svg"
    }
  }), _c('div', [_vm._v("로그아웃")])], 1)], 1)], _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-menu', {
    attrs: {
      "transition": "slide-y-transition",
      "close-on-content-click": false,
      "offset-y": "",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "header-menu-button",
          attrs: {
            "text": "",
            "plain": "",
            "width": "24",
            "min-width": "24"
          }
        }, 'v-btn', attrs, false), on), [_c('v-img', {
          staticClass: "header-menu-icon",
          attrs: {
            "width": "24",
            "src": "/images/icon/icon-search.svg"
          }
        }), _c('div', [_vm._v("검색")])], 1)];
      }
    }])
  }, [_c('v-sheet', [_c('div', {
    staticClass: "pa-16px"
  }, [_c('header-search-bar')], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-menu-button",
    attrs: {
      "text": "",
      "plain": "",
      "width": "24",
      "min-width": "24"
    }
  }, [_c('v-badge', {
    attrs: {
      "overlap": "",
      "content": "1"
    }
  }, [_c('v-img', {
    staticClass: "header-menu-icon",
    attrs: {
      "width": "24",
      "src": "/images/icon/icon-wishlist.svg"
    }
  })], 1), _c('div', [_vm._v("위시리스트")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-menu-button",
    attrs: {
      "text": "",
      "plain": "",
      "width": "24",
      "min-width": "24"
    }
  }, [_c('v-badge', {
    attrs: {
      "overlap": "",
      "content": "1"
    }
  }, [_c('v-img', {
    staticClass: "header-menu-icon",
    attrs: {
      "width": "24",
      "src": "/images/icon/icon-cart.svg"
    }
  })], 1), _c('div', [_vm._v("장바구니")])], 1)], 1), _c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-menu-button",
    attrs: {
      "text": "",
      "plain": "",
      "width": "24",
      "min-width": "24"
    },
    on: {
      "click": _vm.toggleDrawer
    }
  }, [_c('v-img', {
    staticClass: "header-menu-icon",
    attrs: {
      "width": "24",
      "src": "/images/icon/icon-menu.svg"
    }
  }), _c('div', [_vm._v("메뉴")])], 1), _c('header-drawer', {
    attrs: {
      "drawer": _vm.drawer
    },
    on: {
      "toggle-drawer": _vm.toggleDrawer
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }