<template>
    <div class="header-head">
        <v-row justify="end">
            <!-- <template v-for="item in items">
                <v-col :key="item" cols="auto">
                    <router-link :to="item.link" class="header-head__button">
                        <v-img :src="item.icon" class="header-head__icon" />
                        {{ item.name }}
                    </router-link>
                </v-col>
            </template> -->
            <template v-if="!accessToken">
                <v-col cols="auto">
                    <router-link to="/login" class="header-head__button">
                        <v-img src="/images/icon/icon-user.svg" class="header-head__icon" />
                        로그인
                    </router-link>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="auto">
                    <router-link to="/mypage/dashboard" class="header-head__button">
                        <v-img src="/images/icon/icon-user.svg" class="header-head__icon" />
                        마이페이지
                    </router-link>
                </v-col>
                <v-col cols="auto">
                    <div class="header-head__button cursor-pointer" @click="logout">
                        <v-img src="/images/icon/icon-user.svg" class="header-head__icon" />
                        로그아웃
                    </div>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            // {
            //     link: "/",
            //     icon: "/images/icon/icon-professionals.svg",
            //     name: "Professionals",
            // },
            // {
            //     link: "/",
            //     icon: "/images/icon/icon-locations.svg",
            //     name: "Store Locations",
            // },
        ],
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    methods: {
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header-head {
    &__button {
        display: flex;
        align-items: center;
        font-family: var(--font-secondary);
        font-size: 14px;
        font-weight: 300;
        line-height: 1;
    }
    &__icon {
        max-width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}
</style>