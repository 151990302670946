var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-body"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('header-navigation')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('header-menu')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }