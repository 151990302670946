var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit font-tertiary"
  }, [_vm._v("인기 제품")])]), _c('v-tabs', {
    staticClass: "mb-20px mb-md-40px",
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.popularTab,
      callback: function ($$v) {
        _vm.popularTab = $$v;
      },
      expression: "popularTab"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-tab', {
      key: item
    }, [_c('v-btn', {
      staticClass: "rounded-xs",
      attrs: {
        "large": ""
      }
    }, [_vm._v(" " + _vm._s(item.tab) + " ")])], 1)];
  })], 2), _c('v-tabs-items', {
    model: {
      value: _vm.popularTab,
      callback: function ($$v) {
        _vm.popularTab = $$v;
      },
      expression: "popularTab"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-tab-item', {
      key: item,
      attrs: {
        "eager": ""
      }
    }, [_c('v-row', {
      staticClass: "row--lg"
    }, [_vm._l(item.children, function (child) {
      return [_c('v-col', {
        key: child,
        attrs: {
          "cols": "6",
          "lg": "3"
        }
      }, [_c('v-hover', {
        scopedSlots: _vm._u([{
          key: "default",
          fn: function (_ref) {
            var hover = _ref.hover;
            return [_c('v-card', {
              staticClass: "popular-card",
              attrs: {
                "tile": "",
                "flat": "",
                "to": child.link
              }
            }, [_c('v-img', {
              staticClass: "mb-12px mb-md-16px",
              attrs: {
                "eager": "",
                "src": child.image
              }
            }), _c('div', {
              staticClass: "tit tit--xs mb-6px mb-md-8px"
            }, [_vm._v(" " + _vm._s(child.title) + " ")]), _c('div', {
              staticClass: "txt tit--xs mb-12px mb-md-16px"
            }, [_vm._v(" " + _vm._s(child.text) + " ")]), _c('div', {
              staticClass: "popular-card__button d-flex align-center"
            }, [_c('span', {
              staticClass: "txt txt--xs txt--dark font-weight-medium"
            }, [_vm._v(" " + _vm._s(child.title) + " 보러 가기 ")]), _c('v-img', {
              staticClass: "ml-8px",
              attrs: {
                "max-width": "24",
                "src": hover ? '/images/icon/icon-arrow-right-active.svg' : '/images/icon/icon-arrow-right.svg'
              }
            })], 1)], 1)];
          }
        }], null, true)
      })], 1)];
    })], 2)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }