<template>
    <section class="main-visual">
        <!-- <swiper class="swiper" v-bind="{ options }"> -->
        <!-- <swiper-slide> -->
        <v-card img="/images/main/visual.jpg" tile flat class="main-visual__inner">
            <v-container>
                <v-sheet color="white" class="main-visual__text">
                    <h2 class="tit font-tertiary font-weight-regular mt-auto mb-12px mb-md-16px">New: Antao</h2>
                    <p class="txt txt--sm">자연에서 영감을 받은 새로운 전체적인 욕실 컬렉션</p>
                    <v-btn text large :ripple="false" class="mt-8px pa-0">
                        <span class="txt txt--xs txt--dark font-weight-medium">Antao 보러 가기</span>
                        <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                    </v-btn>
                </v-sheet>
            </v-container>
        </v-card>
        <!-- </swiper-slide> -->
        <!-- </swiper> -->
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.main-visual {
    &__inner {
        margin: 0 auto;
        max-width: 1920px;
        aspect-ratio: 15/13;
    }
    .container {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
    &__text {
        max-width: 410px;
        padding: 16px var(--container-gutter) 16px 0;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -100vw;
            width: 100vw;
            height: 100%;
            background-color: #fff;
        }
    }
}
@media (min-width: 768px) {
    .main-visual {
        &__inner {
            aspect-ratio: 1900/816;
        }
        &__text {
            max-width: initial;
            padding: 32px 50px 0 0;
        }
    }
}
</style>
