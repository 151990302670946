var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-navigation"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "auto"
      }
    }, [item.children ? [_c('div', {
      staticClass: "navigation-item",
      on: {
        "click": function ($event) {
          return _vm.toggleDrawer(index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])] : [_c('router-link', {
      staticClass: "navigation-item",
      attrs: {
        "to": item.value
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])]], 2)];
  })], 2), _c('header-drawer', {
    attrs: {
      "drawer": _vm.drawer,
      "activeTab": _vm.activeTab
    },
    on: {
      "toggle-drawer": _vm.toggleDrawer
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }