<template>
    <div class="footer footer-navigation">
        <div class="footer-top-button">
            <v-btn icon large elevation="4" color="white" @click="scrollToTop">
                <v-img max-width="24" src="/images/icon/icon-arrow-up.svg"></v-img>
            </v-btn>
        </div>
        <v-container class="container--lg">
            <v-row>
                <template v-for="item in items">
                    <v-col cols="12" md="3" :key="item">
                        <template v-if="item.value">
                            <a :href="item.value" class="txt txt--sm txt--dark font-weight-medium font-secondary pb-18px pb-md-24px">
                                {{ item.text }}
                            </a>
                        </template>
                        <template v-else>
                            <div class="txt txt--sm txt--dark font-weight-medium font-secondary pb-18px pb-md-24px">
                                {{ item.text }}
                            </div>
                        </template>
                        <v-row class="row--sm">
                            <template v-if="item.text === 'Products'">
                                <template v-for="category in categories">
                                    <v-col cols="12" :key="category">
                                        <a :href="`/shop?category=${category.code}`" class="txt txt--sm txt--dark font-weight-light">
                                            {{ category.name }}
                                        </a>
                                    </v-col>
                                </template>
                            </template>
                            <template v-else>
                                <template v-for="child in item.children">
                                    <v-col cols="12" :key="child">
                                        <a :href="child.value" class="txt txt--sm txt--dark font-weight-light">
                                            {{ child.text }}
                                        </a>
                                    </v-col>
                                </template>
                            </template>
                        </v-row>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapState } from "vuex";
import navigation from "@/store/ui/index.js";

export default {
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
    },
    data: () => ({
        items: navigation,
    }),
    computed: {
        ...mapState(["categories"]),
    },
};
</script>

<style lang="scss" scoped>
.footer-navigation {
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.footer-top-button {
    position: absolute;
    top: -22px;
    right: 10%;
    .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #fff;
    }
    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (min-width: 768px) {
    .footer-navigation {
        padding-top: 68px;
    }
}
</style>