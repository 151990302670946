<template>
    <client-page class="app--main">
        <main-visual />

        <main-design class="main-section--first" />
        <main-ideas />
        <main-collections />
        <main-sales />
        <main-popular />
        <main-story />
        <main-highlights />
        <main-about class="main-section--last" />
        
        <popup-layer v-for="popup in popups" :key="popup._id" :popup="popup" />
    </client-page>
</template>

<script>
import { DISPLAY_CODES, EXHIBITION_GROUPS } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainVisual from "@/components/client/main/main-visual/main-visual.vue";
import MainIdeas from '@/components/client/main/main-ideas.vue';
import MainSales from '@/components/client/main/main-sales.vue';
import MainStory from '@/components/client/main/main-story.vue';
import MainAbout from '@/components/client/main/main-about.vue';
import MainDesign from '@/components/client/main/main-design.vue';
import MainCollections from '@/components/client/main/main-collections.vue';
import MainPopular from '@/components/client/main/main-popular.vue';
import MainHighlights from '@/components/client/main/main-highlights.vue';

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        ClientPage,
        PopupLayer,
        MainVisual,
        MainIdeas,
        MainSales,
        MainStory,
        MainAbout,
        MainDesign,
        MainCollections,
        MainPopular,
        MainHighlights,
    },
    data: () => ({
        popups: [],

        groups,
        DISPLAY_CODES,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getPopups();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getPopups() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/popups");
                    this.popups = res.data.popups;
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding: 0;
    }
}

// top link
.top-link {
    background-color: #182954;
    &__lists {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            left: 0;
            background-color: #182954;
        }
    }
    &__list {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(255, 255, 255, 0.5);
        }
        &:first-child::before {
            display: none;
        }
    }
    &__list-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        line-height: 1.2;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        padding: 4px 14px;
        transition: all ease-out 0.2s;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .top-link {
        &__lists {
            // padding-left: 52px;
            > .top-link__list:first-child {
                margin-left: -20px;
            }
            &::after {
                display: none;
            }
        }
        &__list-link {
            height: 52px;
            padding: 6px 20px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 1024px) {
    .top-link {
        .container {
            padding: 0;
        }
    }
}
</style>
