var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h1', {
    staticClass: "logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/logo.svg"
    }
  })], 1)], 1)]), _c('v-col', [_c('div', {
    staticClass: "header-inner"
  }, [_c('v-row', {
    staticClass: "h-100 align-content-lg-space-between",
    attrs: {
      "no-gutters": "",
      "justify": "end",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('header-head')], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "12"
    }
  }, [_c('header-body')], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "py-16px"
  }, [_c('header-search-bar')], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }