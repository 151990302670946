<template>
    <div class="header-body">
        <v-row justify="space-between" align="center">
            <v-col cols="auto" class="d-none d-lg-block">
                <header-navigation />
            </v-col>
            <v-col cols="auto">
                <header-menu />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import HeaderNavigation from "@/components/client/header/header-body/header-navigation.vue";
import HeaderMenu from "@/components/client/header/header-body/header-menu.vue";

export default {
    components: {
        HeaderNavigation,
        HeaderMenu,
    },
};
</script>