<template>
    <v-card :to="`/shop/products/${value._id}`" height="100%" flat tile class="shop-product-card">
        <like-btn :params="{ _product: value?._id }">
            <template #activator="{ active, toggle }">
                <v-btn icon class="like-button favorite white v-size--xx-small" @click.stop.capture="toggle">
                    <template v-if="active">
                        <div class="like-button-icon like-button-icon--active" />
                    </template>
                    <template v-else>
                        <div class="like-button-icon" />
                    </template>
                </v-btn>
            </template>
        </like-btn>
        <v-card flat tile :img="value.thumb?.url">
            <v-responsive :aspect-ratio="1 / 1" />
        </v-card>
        <v-card-text>
            <div class="txt txt--sm mb-16px font-weight-medium text-truncate-2">{{ value.name }} {{ value.code || "" }}</div>

            <template v-if="!value.sells || value.stock == 0">
                <em class="txt txt--sm">품절</em>
            </template>
            <template v-else>
                <template v-if="discountPrice">
                    <div class="txt font-size-12 text-decoration-line-through">{{ value.price.format() }}</div>
                </template>
                <v-row class="row--xs">
                    <template v-if="discountRate">
                        <v-col cols="auto">
                            <div class="txt txt--sm primary--text font-weight-medium">{{ discountRate }}%</div>
                        </v-col>
                    </template>
                    <v-col cols="auto">
                        <div class="d-flex align-center">
                            <span class="txt txt--sm txt--dark font-weight-medium">{{ salePrice.format() }}</span>
                            <span class="txt font-size-12 ml-4px">원</span>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import LikeBtn from "@/components/client/like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        value: { type: Object, default: null },
    },
    computed: {
        ...mapGetters(["getDiscountPrice", "getDiscountRate", "getSalePrice"]),
        discountPrice() {
            return this.getDiscountPrice(this.value);
        },
        discountRate() {
            return this.getDiscountRate(this.value);
        },
        salePrice() {
            return this.getSalePrice(this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.shop-product-card {
    transition: 0.2s ease-out;
    position: relative;
    &:hover {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
    }
    .like-button {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
        &::before {
            display: none;
        }

        .like-button-icon {
            width: 20px;
            height: 20px;
            background-image: url(/images/icon/icon-like.svg);
            background-size: contain;
            transition: all 0.2s ease-out;

            &--active {
                background-image: url(/images/icon/icon-like-active.svg);
            }
        }

        &:hover {
            .like-button-icon {
                background-image: url(/images/icon/icon-like-active.svg);
            }
        }
    }
}
</style>