<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header v-bind="{ disableScrollTop }" />
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer />
        <!-- E: FOOTER -->
    </v-app>
</template>

<script>
import { mapActions } from "vuex";

import VueStickyDirective from "@renatodeleao/vue-sticky-directive";

import MainFooter from "@/components/client/inc/main-footer.vue";
import MainHeader from "@/components/client/inc/main-header.vue";

import "@mdi/font/css/materialdesignicons.css"
export default {
    components: {
        MainFooter,
        MainHeader,
    },
    props: {
        hasCustomAside: { type: Boolean, default: false },
        disableScrollTop: { type: Boolean, default: false },
        contentsColClass: { type: String, default: "flex-grow-1" },
        contentsClass: { type: String, default: "mw-1330px" },
    },
    directives: {
        VueStickyDirective,
    },
    data: () => ({
        loading: false,

        stickyOptions: {
            resizeSensor: true,
            topSpacing: 12,
            bottomSpacing: 12,
        },
    }),
    mounted() {
        this.init();
        this.enlipleTracker();
        this.naverWcs();
    },
    watch: {
        "$route.path"() {
            this.enlipleTracker();
            this.naverWcs();
        },
    },
    methods: {
        ...mapActions("enlipleTracker", ["callMtm"]),
        ...mapActions("naverWcs", ["wcs_do"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.$nextTick(() => {
                    setTimeout(() => {}, 500);
                });
            } finally {
                this.loading = false;
            }
        },
        async enlipleTracker() {
            const toCall = !["/shop/products/", "/shop/result"].some((path) => this.$route.path.includes(path));
            if (toCall) this.callMtm();
        },
        async naverWcs() {
            if (!this.$route.path.includes("/shop/result")) this.wcs_do();
        },
    },
};
</script>

<style lang="scss" scoped></style>
