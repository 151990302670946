var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-head"
  }, [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [!_vm.accessToken ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "header-head__button",
    attrs: {
      "to": "/login"
    }
  }, [_c('v-img', {
    staticClass: "header-head__icon",
    attrs: {
      "src": "/images/icon/icon-user.svg"
    }
  }), _vm._v(" 로그인 ")], 1)], 1)] : [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "header-head__button",
    attrs: {
      "to": "/mypage/dashboard"
    }
  }, [_c('v-img', {
    staticClass: "header-head__icon",
    attrs: {
      "src": "/images/icon/icon-user.svg"
    }
  }), _vm._v(" 마이페이지 ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "header-head__button cursor-pointer",
    on: {
      "click": _vm.logout
    }
  }, [_c('v-img', {
    staticClass: "header-head__icon",
    attrs: {
      "src": "/images/icon/icon-user.svg"
    }
  }), _vm._v(" 로그아웃 ")], 1)])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }