var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-design', {
    staticClass: "main-section--first"
  }), _c('main-ideas'), _c('main-collections'), _c('main-sales'), _c('main-popular'), _c('main-story'), _c('main-highlights'), _c('main-about', {
    staticClass: "main-section--last"
  }), _vm._l(_vm.popups, function (popup) {
    return _c('popup-layer', {
      key: popup._id,
      attrs: {
        "popup": popup
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }