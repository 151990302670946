<template>
    <main-section>
        <v-container>
            <v-sheet class="section-bg" />

            <v-row align="center" class="row--lg">
                <v-col cols="12" md="7" order-md="2">
                    <v-img src="/images/main/ideas.jpg" />
                </v-col>
                <v-col cols="12" md="5">
                    <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">욕실 아이디어</h2>
                    <p class="txt txt--sm">
                        욕실의 조화로운 디자인과 최적의 기능성을 위해 <br class="d-none d-md-block" />
                        - 다양한 욕실 아이디어를 찾아보세요
                    </p>
                    <v-btn large tile class="white mt-18px mt-md-24px px-20px px-md-26px rounded-xs">
                        <span class="txt txt--xs txt--dark font-weight-medium">Antao 보러 가기</span>
                        <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </main-section>
</template>

<script>
import MainSection from "@/components/client/templates/main-section.vue";

export default {
    components: {
        MainSection,
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
@media (min-width: 768px) {
    .container {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .section-bg {
        width: calc(100% / 12 * 7);
        height: 100%;
    }
}
</style>