var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit font-tertiary"
  }, [_vm._v("제품 하이라이트")])]), _c('v-tabs', {
    staticClass: "mb-20px mb-md-40px",
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.highlightsTab,
      callback: function ($$v) {
        _vm.highlightsTab = $$v;
      },
      expression: "highlightsTab"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-tab', {
      key: item
    }, [_c('v-btn', {
      staticClass: "rounded-xs",
      attrs: {
        "large": ""
      }
    }, [_vm._v(" " + _vm._s(item.tab) + " ")])], 1)];
  })], 2), _c('v-tabs-items', {
    model: {
      value: _vm.highlightsTab,
      callback: function ($$v) {
        _vm.highlightsTab = $$v;
      },
      expression: "highlightsTab"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-tab-item', {
      key: item
    })];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }