var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "partnered"
  }, [_vm._v("partnered with Joeyforlife")]), _c('v-menu', {
    staticClass: "family-site-list",
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-card', _vm._g(_vm._b({
          staticClass: "family-site-list__button",
          attrs: {
            "outlined": "",
            "color": "grey darken-3"
          }
        }, 'v-card', {
          attrs
        }, false), on), [_c('div', [_vm._v("family sites"), _c('v-icon', {
          attrs: {
            "color": "grey darken-3"
          }
        }, [_vm._v("mdi-menu-down")])], 1)])];
      }
    }])
  }, [_c('v-list', {
    staticClass: "pa-0"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-list-item', {
      key: item,
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }