var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-search-bar"
  }, [_c('v-img', {
    staticClass: "header-search-bar__icon",
    attrs: {
      "width": "24",
      "src": "/images/icon/icon-search.svg"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "placeholder": "원하는 상품을 찾아보세요."
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }